<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item" v-if="memberLogin?.phone_number">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/home`"
          navText="Home">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">home</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="checkMerchantConnectedStripe === 'true' && memberPermissions.includes('Top-up')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/top-up`"
          navText="Top-up">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">shopping_bag</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="checkMerchantConnectedStripe === 'true' && memberPermissions.includes('Donation')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/donation`"
          navText="Donation">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">paid</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="checkMerchantConnectedStripe === 'true' && memberLogin?.phone_number && merchantCode == '/svkt'">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/booking`"
          navText="Booking">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">calendar_month</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Admissions')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" @click="toggleSidebar()"
          :collapseRef="`${merchantCode}/admissions`" navText="Attendance">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Transactions')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" @click="toggleSidebar()"
          :collapseRef="`${merchantCode}/my-transactions`" navText="Transactions">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('My Rewards')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/my-rewards`"
          navText="My Rewards">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('My Campaigns')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/my-campaigns`"
          navText="My Challenges">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">campaign</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Reward Catelogue')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/store`"
          navText="Store">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">shopping_bag</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Reward Catelogue')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/reward-catalogue`"
          navText="Reward Catalogue">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">shopping_bag</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" @click="toggleSidebar()"
          :collapseRef="`${merchantCode}/profile`" navText="Update Profile">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Invite Friends')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/invite-friends`"
          navText="Invite Friends">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">groups</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Find Branches')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/find-branches`"
          navText="Find Branches">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">location_on</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('FAQ')">
        <a href="https://www.lioncitysailorsfc.sg/memberships/#" target="_blank" class="nav-link" data-bs-toggle="" aria-controls="" aria-expanded="false" url="#">
          <div class="text-center d-flex align-items-center justify-content-center me-2">
            <i class="material-icons-round opacity-10 fs-5">help</i>
          </div>
          <span class="nav-link-text ms-1">FAQ</span>
        </a>
      </li>
      <li class="nav-item" v-if="memberPermissions.includes('Contact Us')">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/contact-us`"
          navText="Contact Us">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5" @click="toggleSidebar()">contact_mail</i>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="/admin/merchants"
          navText="Merchants">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="/admin/members"
          navText="Members">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="/admin/setting"
          navText="Setting">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" :collapseRef="`${merchantCode}/sign-in`" @click="logOut()"
          navText="Logout">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">login</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapMutations } from "vuex";
import MemberService from "../../services/member.service";
import EventBus from "vue3-eventbus";
import TokenService from "../../services/token.service";
import { localStorageName, getMerchantCode } from "@/config/utils";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      merchantCode: "",
      checkMerchantConnectedStripe: false,
      memberPermissions: [],
      memberLogin: null
    };
  },
  created() {
    this.getMemberInfo();
    const token = TokenService.getLocalAccessToken();
    if(token) {
      this.checkMemberPermissions();
    }
  },  
  mounted() {
    const code = getMerchantCode();
    const isCheckMerchantConnectedStripe = localStorage.getItem(localStorageName("IS_CHECK_MERCHANT_CONNECTED_STRIPE"), 'false');
    this.merchantCode = code ? `/${code}` : ''
    this.checkMerchantConnectedStripe = isCheckMerchantConnectedStripe;
    EventBus.on("reloadSideNav", () => {
      this.getMemberInfo();
      this.checkMemberPermissions();
    });
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
    logOut() {
      this.$store.dispatch("auth/logout")
    },
    checkMemberPermissions() {
      MemberService.checkMemberPermissions().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.memberPermissions = response.data.data;
          }
        },
        (error) => {
          console.log(error)
        }
      );
    },
    getMemberInfo() {
      MemberService.getProfile().then(
          (response) => {
              if (response.data.result.isSuccess) {
                this.memberLogin = response.data.data;
              }
          },
          (error) => {
            console.log("error", error);
          }
      );
    },
  },
  components: {
    SidenavCollapse,
  }
};
</script>

<style scoped>
.navbar-vertical.navbar-expand-xs .navbar-collapse {
  overflow: unset;
}
</style>
